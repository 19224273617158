<template>
    <div v-if="data">
        <van-tabs v-model="active" :color="'#5D5F63'">
            <!-- 交话费 start-->
            <van-tab title="交话费">
                <div class="input cost-form">
                    <input class="ipt" type="text" id="ipt" maxlength="11" placeholder="请输入手机号" @input="onInput"
                        readonly @click.stop="onMobilePhoneClick" v-model="phone" />
                    <em class="input-delete" @click="deleteHeadler">×</em>
                    <div class="history" v-if="isHistory && history != null">
                        <p @click="historyPhone(item)" v-for="(item, index) in history" :key="index">
                            {{ item }}
                        </p>
                    </div>
                    <span>请仔细核对充值号码，充错号将无法退款</span>
                    <p v-if="myMobilePhone && myMobilePhone == phone">
                        账户可用余额：<em>{{ data.balance }}元</em>
                    </p>
                </div>
                <ul class="rechar_list">
                    <li v-for="(vo, index) in cost" :class="{ rechar_active: rechar_li == index }" :key="index"
                        @click="rechar(index)">
                        {{ vo.cost }}
                    </li>
                    <li>
                        <input @blur="iptBlur()" @focus="iptFocus()" v-model="iptMoney" type="number" @input="iptMoney"
                            readonly @click.stop="onMoneyClick" />
                        <span id="placeholder">{{ placeholder }}</span>
                    </li>
                </ul>
                <van-button round type="info" @click="callRecharge">立即充值</van-button>
                <Popup :show="show" @cancel="onCancel()" @confirm="rechargeAddOrder()">
                    <div slot="conter" class="conter">
                        <p class="conter_phone">
                            <span>充值手机号:</span><em>{{ phone }}</em>
                        </p>
                        <p class="conter_money">
                            <span>充值金额:</span>
                            <em v-if="rechar_li != null">{{
                                cost[rechar_li].cost
                                }}</em>
                            <em v-if="rechar_li == null">{{
                                iptMoney + "元"
                                }}</em>
                        </p>
                    </div>
                </Popup>
            </van-tab>
            <!-- 交话费 end-->
            <van-tab title="充流量" v-if="flow">
                <div class="input" v-if="myMobilePhone">
                    <input class="ipt" type="text" id="ipt" maxlength="11" placeholder="请先绑定手机号" v-model="myMobilePhone"
                        disabled />
                    <div class="getCode">
                        <input type="text" maxlength="8" placeholder="请输入验证码" v-model="code" />
                        <button class="tab4_btn" :disabled="isNone" @click="codeHeadler">
                            {{ verifyCode }}
                        </button>
                    </div>

                    <load :load="load">
                        <p slot="text" class="loadText">短信发送中...</p>
                    </load>
                </div>
                <van-tabs class="Recharge-box" type="card" v-model="flowTabActive">
                    <!-- <van-tab title="流量加油包" v-if="flag == true">
                       <ul class="rechar_list">
                            <li v-for="(item, index) in dayFlow" :class="{ rechar_active: dayFlow_li == index }"
                                :key="index" @click="flowClicks(index, 'dayFlow')">
                                <p>{{ item.offerZname }}</p>
                                <p>{{ item.offerfee }}</p>
                            </li>
                        </ul>
                        <div class="describe" v-html="data.dayFlow.desc"></div>
                        <van-button round type="info" @click="flowRecharges">立即充值</van-button>
                    </van-tab> -->

                    <van-tab title="流量月包">
                        <ul class="rechar_list">
                            <li v-for="(item, index) in monthFlow" :class="{
                                rechar_active: monthFlow_li == index,
                            }" :key="index" @click="flowClick(index, 'monthFlow')">
                                <p>{{ item.flow }}</p>
                                <p>{{ item.money }}</p>
                            </li>
                        </ul>
                        <div class="describe" v-html="data.monthFlow.desc"></div>
                        <van-button round type="info" @click="flowRecharge">立即充值</van-button>
                    </van-tab>
                </van-tabs>

                <Popup :show="shows" confirmText="确认开通" @cancel="onCancels()" @confirm="openFlows()">
                    <div slot="conter" class="conter">
                        <p class="conter_phone">
                            <span>充值手机号:</span><em>{{ myMobilePhone }}</em>
                        </p>
                        <p class="conter_flow">
                            <span>流量包名称:</span><em>
                                {{ offerZname }}
                            </em>
                        </p>
                        <p class="conter_money">
                            <span>支付金额:</span><em>{{ offerfee }}</em>
                        </p>
                    </div>
                    <div class="bottom" slot="bottom">
                        流量包费用将在您当前可用余额中直接扣除
                    </div>
                </Popup>

                <Popup :show="show" confirmText="确认开通" @cancel="onCancel()" @confirm="openFlow()">
                    <div slot="conter" class="conter">
                        <p class="conter_phone">
                            <span>充值手机号:</span><em>{{ myMobilePhone }}</em>
                        </p>
                        <p class="conter_flow">
                            <span>流量包名称:</span><em>
                                {{ flowPackageName }}
                            </em>
                        </p>
                        <p class="conter_money">
                            <span>支付金额:</span><em>{{ flowPackagePrice }}</em>
                        </p>
                    </div>
                    <div class="bottom" slot="bottom">
                        流量包费用将在您当前可用余额中直接扣除
                    </div>
                </Popup>
            </van-tab>
        </van-tabs>

        <van-number-keyboard :value="phone" :show="numberKeyboardShow" @input="onNumberKeyboardInput"
            @delete="onNumberKeyboardDelete" @blur="onNumberKeyboardBlur" />
        <van-number-keyboard :value="iptMoney" :show="MoneyShow" @input="onMoneyInput" @delete="onMoneyDelete"
            @blur="onMoneyBlur" />
    </div>
</template>

<script>
import { mapState } from "vuex"
import Popup from "../../components/Popup.vue"
import Lanmao from "../../components/lanmao/index"
import Vue from "vue"
import load from "../../components/load.vue"
import { Toast } from "vant"
import API from "../../common/api/api"
import { Dialog, NumberKeyboard, Field } from "vant"
Vue.use(Toast).use(NumberKeyboard)
export default {
    name: "Recharge",
    data() {
        return {
            offerZname: "",
            offerfee: "",
            iptMoney: "",
            active: 0,
            placeholder: "请输入10元及以上的金额",
            rechar_active: "rechar_active",
            rechar_li: null,
            dayFlow_li: null,
            monthFlow_li: null,
            show: false,
            shows: false,
            phone: null,
            productId: "",
            myMobilePhone: "", //当前账号绑定的手机号
            balance: 0.0,
            cost: [],
            dayFlow: null,
            monthFlow: null,
            monthFlowDesc: null,
            dayFlowDesc: null,
            ordersId: null,
            payData: null,
            flowPackageName: "",
            flowPackagePrice: "",
            flowTabActive: 0,
            data: null,
            numberKeyboardShow: false,
            numberKeyboardBlurEvent: false,
            MoneyShow: false,
            MoneyBlurEvent: false,
            history: [],
            isHistory: false,
            code: "",
            flags: false,
            flow: false,
        }
    },
    watch: {
        flowTabActive(newval) {
            if (newval == 1) {
                this.dayFlow_li = null
            } else {
                this.monthFlow_li = null
            }
            this.productId = null
        },
    },
    computed: {
        ...mapState(["member", "verifyCode", "isNone", "load"]),
    },
    components: {
        Popup,
        NumberKeyboard,
        Field,
        load,
    },
    created() {
        this.getRechargeProduct()
        let historyList = JSON.parse(localStorage.getItem("phoneList"))
        this.history = historyList
    },
    methods: {
        //获取验证码
        codeHeadler() {
            let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
            if (this.myMobilePhone == "") {
                Toast({
                    message: "请输入手机号",
                    position: "bottom",
                })
            } else if (!phone.test(this.myMobilePhone)) {
                Toast({
                    message: "请输入正确的手机号",
                    position: "bottom",
                })
            } else {
                this.$store.commit("obtainCode", {
                    mobilePhone: this.myMobilePhone,
                    type: "07",
                })
            }
        },
        //历史记录
        historyPhone(phone) {
            this.phone = phone
            this.isHistory = false
        },
        //
        onMobilePhoneClick() {
            if (!this.numberKeyboardShow && !this.numberKeyboardBlurEvent) {
                this.numberKeyboardShow = true
                this.isHistory = true
            }
        },
        onMoneyClick() {
            if (!this.MoneyShow && !this.MoneyBlurEvent) {
                this.MoneyShow = true
            }
        },
        //键盘失去焦点
        onNumberKeyboardBlur() {
            this.numberKeyboardBlurEvent = true
            this.numberKeyboardShow = false
            setTimeout(() => {
                this.numberKeyboardBlurEvent = false
                this.isHistory = false
            }, 500)
        },
        onMoneyBlur() {
            this.MoneyBlurEvent = true
            this.MoneyShow = false
            setTimeout(() => {
                this.MoneyBlurEvent = false
            }, 500)
        },
        //键盘输入
        onNumberKeyboardInput(val) {
            if (this.phone.length < 11) {
                this.phone += val
            }
        },
        onMoneyInput(val) {
            if (this.iptMoney == "" && val == 0) {
                this.iptMoney = ""
            } else {
                this.iptMoney += val
            }
        },
        //键盘删除
        onNumberKeyboardDelete() {
            this.phone = this.phone.slice(0, -1)
        },
        onMoneyDelete() {
            this.iptMoney = this.iptMoney.slice(0, -1)
        },
        //获取充值产品
        getRechargeProduct() {
            API.getJson("RECHARGE_PRODUCT", {}).then(res => {
                if (res.serviceKind == 7) {
                    if (res.monthFlow.isShow == 1) {
                        this.flow = true
                    } else {
                        this.flow = false
                    }
                } else if (res.serviceKind == 8) {
                    this.flow = false
                }
                this.data = res
                this.cost = this.data.call.list
                this.dayFlow = this.data.traffic.list
                this.monthFlow = this.data.monthFlow.list

                this.monthFlowDesc = this.data.monthFlow.desc
                this.dayFlowDesc = this.data.dayFlow.desc
                this.myMobilePhone = this.data.mobilePhone
                this.myMobilePhone == "NULL" || this.myMobilePhone == "null" ? this.phone = "" : this.phone = this.myMobilePhone
                this.balance = this.data.balance
                // this.monthFlow.forEach(element => {
                //     if (element.kind != this.data.serviceKind) {
                //         this.flow = false
                //     } else {
                //         this.flow = true
                //     }
                // });
            })
        },
        //输入手机号
        onInput(e) {
            this.phone = this.phone.replace(/[^\d]/g, "")
        },
        onInputMoney(e) {
            this.iptMoney = this.iptMoney.replace(/[^\d]/g, "")
        },
        //开通流量
        openFlow() {
            this.show = false
            API.getJson("OPEN_FLOW", {
                productId: this.productId,
                code: this.code,
            })
                .then(res => {
                    if (res.data) {
                        if (res.data.code == -100) {
                            Toast({
                                message: res.data.msg,
                                icon: "none",
                            })
                        }
                    } else {
                        if (res.msg == "sucess") {
                            this.$router.push({ path: "/paySuccess" })
                        }
                    }
                })
                .catch(err => {
                    // this.$router.push({ path: "/flowPayFali" })
                })
            //
        },

        //开通加油包
        openFlows() {
            API.getJson("OPEN_FLOW", {
                productId: this.productId,
                code: this.code,
            })
                .then(res => {
                    if (res.data) {
                        if (res.data.code == -100) {
                            Toast({
                                message: res.data.msg,
                                icon: "none",
                            })
                        }
                    } else {
                        if (res.msg == "sucess") {
                            this.$router.push({ path: "/paySuccess" })
                        }
                    }
                })
                .catch(err => {
                    // this.$router.push({ path: "/flowPayFali" })
                })
            //
        },
        //话费充值下单
        rechargeAddOrder() {
            this.show = false

            let params = {
                mobilePhone: this.phone,
                rechargeNum:
                    this.rechar_li == null
                        ? this.iptMoney * 1
                        : this.cost[this.rechar_li].rechargeNum,
            }
            API.getJson("ADD_ORDERS", params).then(res => {
                this.ordersId = res.ordersId
                if (res.data) {
                    if (res.data.code == -100) {
                        Toast({
                            message: res.data.msg,
                            position: "bottom",
                        })
                    }
                } else {
                    if (res.ordersId != "") {
                        this.wechatOfficialAccountsPay()
                    }
                }

                //Toast.clear();

                // this.wechatOfficialAccountsPay()
            })
        },
        //发起支付
        wechatOfficialAccountsPayInvoke(payData) {
            let that = this
            WeixinJSBridge.invoke(
                "getBrandWCPayRequest",
                that.payData,
                function (res) {
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        // 使用以上方式判断前端返回,微信团队郑重提示：
                        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                        console.log("支付成功")
                        let arr = []
                        arr.push(that.phone)
                        let phoneList = JSON.parse(
                            localStorage.getItem("phoneList")
                        )
                        if (phoneList == null || phoneList == "") {
                            console.log("判断储存没有的话")
                            localStorage.setItem(
                                "phoneList",
                                JSON.stringify(arr)
                            )
                        } else {
                            // console.log("判断储存有的话")
                            if (phoneList.indexOf(arr) == -1) {
                                phoneList.push(arr[0])
                                localStorage.setItem(
                                    "phoneList",
                                    JSON.stringify(phoneList)
                                )
                            } else {
                                console.log("该号码以储存")
                            }
                        }
                    }
                }
            )
        },
        //公众号支付
        async wechatOfficialAccountsPay() {
            try {
                let res = await API.getJson("PAY", { ordersId: this.ordersId })
                this.payData = res.payData
                if (typeof WeixinJSBridge == "undefined") {
                    if (document.addEventListener) {
                        document.addEventListener(
                            "WeixinJSBridgeReady",
                            onBridgeReady,
                            false
                        )
                    } else if (document.attachEvent) {
                        document.attachEvent(
                            "WeixinJSBridgeReady",
                            onBridgeReady
                        )
                        document.attachEvent(
                            "onWeixinJSBridgeReady",
                            onBridgeReady
                        )
                    }
                } else {
                    this.wechatOfficialAccountsPayInvoke()
                }
            } catch (err) {
                console.log("公众号支付失败")
            }
        },
        //popup框取消回调
        onCancel() {
            this.show = false
        },
        //popup框取消回调
        onCancels() {
            this.shows = false
        },
        //话费充值
        callRecharge() {
            if (!/^1[3456789]\d{9}$/.test(this.phone)) {
                Toast({
                    message: "请输入正确的手机号",
                    position: "bottom",
                })
            } else if (
                this.rechar_li == null &&
                this.placeholder == "请输入10元及以上的金额"
            ) {
                Toast({
                    message: "请选择您要充值的金额",
                    position: "bottom",
                })
            } else if (this.rechar_li == null && this.iptMoney < 10) {
                Toast({
                    message: "请输入10元及以上的金额",
                    position: "bottom",
                })
            } else {
                this.show = true
            }
        },
        //流量充值
        flowRecharge() {
            //如果为空 提示去绑定手机号
            if (!this.myMobilePhone) {
                Dialog.confirm({
                    title: "提示",
                    message: "您还未绑定手机号，无法使用流量充值服务",
                    confirmButtonText: "去绑定",
                })
                    .then(() => {
                        this.$router.push({ path: "/AccountBind" })
                    })
                    .catch(() => {
                        // on cancel
                    })
                //产品id
            } else if (!this.productId) {
                Toast({
                    position: "bottom",
                    message: "请选择流量套餐",
                })
            } else if (this.code == "") {
                Toast({
                    position: "bottom",
                    message: "请填写验证码",
                })
            } else if (!this.code) {
                Toast({
                    position: "bottom",
                    message: "验证码错误",
                })
            } else {
                this.show = true
            }
            // }else if(){

            //
        },

        //流量包订购
        flowRecharges() {
            //如果为空 提示去绑定手机号
            if (!this.myMobilePhone) {
                Dialog.confirm({
                    title: "提示",
                    message: "您还未绑定手机号，无法使用流量充值服务",
                    confirmButtonText: "去绑定",
                })
                    .then(() => {
                        this.$router.push({ path: "/AccountBind" })
                    })
                    .catch(() => {
                        // on cancel
                    })
                //产品id
            } else if (!this.productId) {
                Toast({
                    position: "bottom",
                    message: "请选择流量套餐",
                })
            } else if (this.code == "") {
                Toast({
                    position: "bottom",
                    message: "请填写验证码",
                })
            } else if (!this.code) {
                Toast({
                    position: "bottom",
                    message: "验证码错误",
                })
            } else {
                this.shows = true
            }
            // }else if(){

            //
        },
        //选择充值的话费
        rechar(index) {
            this.rechar_li = index
        },
        //选择流量套餐
        flowClick(index, type) {
            // this.dayFlow_li = null
            // this.monthFlow_li = null
            this[type + "_li"] = index
            this.productId = this[type][index].id
            this.flowPackageName = this[type][index].flow
            this.flowPackagePrice = this[type][index].money
        },
        //选择加油包
        flowClicks(index, type) {
            this.dayFlow_li = null
            this.monthFlow_li = null
            this[type + "_li"] = index
            this.productId = this[type][index].offerZid
            this.offerZname = this[type][index].offerZname
            this.offerfee = this[type][index].offerfee
        },

        iptBlur() {
            if (this.iptMoney == "") {
                this.placeholder = "请输入10元及以上的金额"
            } else {
                this.placeholder = ""
            }
        },
        iptFocus() {
            this.placeholder = ""
            this.rechar_li = null
            this.productId = ""
            console.log(this.rechar_li)
        },
        //删除手机号
        deleteHeadler() {
            this.phone = ""
        },
    },
}
</script>

<style lang="scss" scoped>
.box {
    height: 100vh;
    overflow: scroll;
}

.getCode {
    position: relative;
    width: 100%;
    height: px2rem(100);
    background-color: #fff;
    font-size: px2rem(26);
    // padding: 0 px2rem(30);
    border-bottom: 1px solid #dfe0e7;
    box-sizing: border-box;
}

.getCode input {
    width: 100%;
    height: px2rem(100);
    font-size: px2rem(34);
    box-sizing: border-box;
}

.getCode .tab4_btn {
    position: absolute;
    right: px2rem(30);
    top: px2rem(25);
    padding: 0 px2rem(25);
    height: px2rem(50);
    line-height: px2rem(50);
    color: #fff;
    font-size: px2rem(24);
    background-color: #336289;
    text-align: center;
    border-radius: px2rem(20);
}

.history {
    position: absolute;
    left: 0;
    top: px2rem(202);
    width: 100%;
    max-height: px2rem(160);
    overflow-y: auto;
    background: #fff;
}

.history p {
    width: 100%;
    height: px2rem(80);
    line-height: px2rem(80) !important;
    color: #fff;
    font-size: px2rem(30) !important;
    padding: 0 px2rem(30);
    box-sizing: border-box;
    border-bottom: px2rem(1) solid #b5b5bb;
    margin: 0 !important;
}

.cost-form {
    position: relative;
}

.input .input-delete {
    position: absolute;
    right: px2rem(50);
    top: px2rem(20);
    color: #999;
    font-size: px2rem(50);
}

.ipt {
    width: 100%;
    height: px2rem(100);
    line-height: px2rem(100);
    font-size: px2rem(50);
    font-weight: bold;
    color: #333333;
    border-bottom: 1px solid #dfe0e7;
    letter-spacing: px2rem(2);
}

.ipt::placeholder {
    color: #333;
    font-size: px2rem(30);
    font-weight: normal;
}

.input span {
    font-size: px2rem(24);
    color: #909198;
}

.input p {
    font-size: px2rem(24);
    color: #333;
    line-height: px2rem(62);
    margin-bottom: px2rem(32);
}

.input p em {
    font-size: px2rem(26);
    color: #ff7505;
}

.rechar_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.rechar_list li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    width: px2rem(170);
    height: px2rem(130);
    font-size: px2rem(26);
    border: 2px solid #abacb1;
    border-radius: px2rem(9);
    margin-bottom: px2rem(15);
    color: #5d5f64;
    font-weight: 600;
    margin-right: px2rem(15);
    box-sizing: border-box;
}

.rechar_list li input {
    position: absolute;
    width: px2rem(170);
    height: px2rem(88);
    font-size: px2rem(26);
    text-align: center;
    z-index: 9;
}

.rechar_list li #placeholder {
    position: absolute;
    width: px2rem(160);
    font-size: px2rem(24);
    color: #999;
    z-index: 8;
}

.rechar_list p {
    flex: 0 0 100%;
    line-height: 0.8rem;
}

.rechar_list p:nth-child(1) {
    margin-top: px2rem(5);
}

.rechar_list p:nth-child(2) {
    color: #ff7505;
    margin-top: px2rem(-20);
}

.van-button {
    width: 100%;
    height: px2rem(80);
    line-height: px2rem(80);
    color: #fff;
    font-weight: bold;
    font-size: px2rem(28);
    background-color: #336289;
    margin-top: px2rem(65);
    margin-bottom: px2rem(300);
    box-shadow: 0 0 px2rem(20) rgba(0, 0, 0, 0.3);
}

.van-tabs--card {
    margin-top: px2rem(30);
}

.describe {
    height: px2rem(270);
    margin-top: px2rem(32);
    overflow: scroll;
}

.describe ::v-deep li {
    font-size: px2rem(22);
    color: #8f9197;
    line-height: px2rem(48);
}

.rechar_active {
    color: #fff !important;
    background-color: #336289;
    box-shadow: 0 px2rem(5) px2rem(10) rgba(0, 0, 0, 0.3);
    border: none !important;
}

.rechar_list .rechar_active p {
    color: #fff;
}

.conter {
    margin-bottom: px2rem(30);
}

.conter p {
    display: flex;
    justify-content: space-between;
    padding: 0 px2rem(80);
    line-height: px2rem(56);
}

.conter p span {
    width: 50%;
    font-size: px2rem(26);
    color: #909198;
}

.conter p em {
    width: 50%;
    text-align: left;
    font-size: px2rem(28);
    color: #333;
    font-weight: 600;
}

.conter .conter_money em {
    color: #ff7505;
}

.conter .conter_flow em {
    color: #336289;
}

.bottom {
    width: 100%;
    height: px2rem(80);
    line-height: px2rem(80);
    font-size: px2rem(24);
    color: #909198;
    border-top: 1px solid #909198;
    text-align: center;
}
</style>